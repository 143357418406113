import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { bannerCMS, sliderCompanies, testimonials, subpageContent } from "../utils/services/subpageInformation";
import ParallaxComponent from "../components/parallax-component/parallax-component"
import InfoImage from "../components/info-image/info-image";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Banner from "../components/banner/banner";




// markup
const Uninsured = () => {


  return (
    <Layout
      SEOTitle={"Uninsured Loss Recovery - United Legal Assistance"}
      SEODescription={"Are you looking for a flexible claims management solution tailored to your needs?"}
      footerDisclaimer={""}>

      <InfoImage props={subpageContent} origin="recovery" />

      <Banner props={bannerCMS} />

      <ImageCarousel props={sliderCompanies} />

      <ImageCarousel props={testimonials} />

    </Layout>
  )

}

export default Uninsured